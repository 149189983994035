import ReactDOM from 'react-dom';
import { useState } from 'react';
import clsx from 'clsx';

export const TooltipPortal = ({ content, children, className }) => {
  const [visible, setVisible] = useState(false);
  const [coords, setCoords] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setCoords({ x: rect.left, y: rect.top });
    setVisible(true);
  };

  const handleMouseLeave = () => {
    setVisible(false);
  };

  return (
    <div
      className={clsx('relative flex items-center', className)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {visible &&
        ReactDOM.createPortal(
          <div
            className='absolute flex flex-col p-2 text-xs font-semibold text-white border rounded bg-perception-black-800'
            style={{
              top: `${coords.y - 30}px`,
              left: `${coords.x + 30}px`,
              transform: 'translateY(-50%) ',
            }}
          >
            {content}
          </div>,
          document.body,
        )}
    </div>
  );
};
