import { Outlet } from 'react-router-dom';
import { LogProvider } from '../../contexts/LogContext';

export const LogLayout = () => {
  return (
    <LogProvider>
      <Outlet />
    </LogProvider>
  );
};
