import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isFreeUser } from '../../services/entity/user/user-service';
import { useUser } from '../../contexts/UserContext';
import { MainPanel } from './components/main-panel/MainPanel';
import { LeftPanel } from './components/left-panel/LeftPanel';
import { PatternPanel } from './components/pattern-panel/PatternPanel';
import { PatternSetupProvider, usePatternSetup } from '../../contexts/PatternSetupContext';
import { RightPanel } from './components/right-panel/RightPanel';

export const ProductionPage = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    if (isFreeUser(user)) {
      navigate('/demo/live');
    }
  }, [user]);

  return (
    <PatternSetupProvider>
      <div id='production-page' className='flex w-full h-full'>
        <ProductionPageView />
      </div>
    </PatternSetupProvider>
  );
};

const ProductionPageView = () => {
  const { isPatternPanelOpened } = usePatternSetup();

  const [focusedLogDate, setFocusedLogDate] = useState(null);

  return (
    <Fragment>
      <div className='flex w-1/6 h-full'>
        <LeftPanel />
      </div>
      <div className={`flex-1 transition-all duration-500 ${isPatternPanelOpened ? 'w-2/6' : 'w-4/6'}`}>
        <MainPanel setFocusedLogDate={setFocusedLogDate} />
      </div>
      <div className={`transition-all duration-500 ${isPatternPanelOpened ? 'w-3/12' : 'w-0'}`}>
        <PatternPanel />
      </div>
      <div className='flex w-1/6 h-full'>
        <RightPanel focusedLogDate={focusedLogDate} />
      </div>
    </Fragment>
  );
};
