/**
 * Checks if two log entries occurred on the same day.
 * It compares the year, month, and day of the 'time' property of both log objects.
 */
const _areSameDay = (log1, log2) => {
  const date1 = new Date(log1.time);
  const date2 = new Date(log2.time);
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};

/**
 * Determines if two log entries are close in time based on a specified aggregation threshold.
 * Checks if both logs occurred on the same day and if the time difference between them
 * is less than or equal to the provided aggregation value (in seconds).
 */
export const _areClose = (log1, log2, aggregation) => {
  if (log1 === undefined || log2 === undefined) return false;
  const date1 = new Date(log1.time);
  const date2 = new Date(log2.time);
  return _areSameDay(log1, log2) && Math.abs((date2 - date1) / 1000) <= aggregation;
};

export const convertTimeToSeconds = (timeStr) => {
  const parts = timeStr.split(':').map(Number);
  let seconds = 0;

  if (parts.length === 3) {
    seconds = parts[0] * 3600 + parts[1] * 60 + parts[2];
  } else if (parts.length === 2) {
    seconds = parts[0] * 60 + parts[1];
  }

  return seconds;
};

export const formatSecondsDuration = (seconds) => {
  if (seconds < 60) {
    return seconds + 's';
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  let result = '';
  if (hours > 0) {
    result += hours + 'h';
  }
  if (minutes > 0) {
    if (result !== '') {
      result += ' ';
    }
    result += minutes + 'min';
  }
  return result;
};

export const convertSmallUnitToSeconds = (timeString) => {
  const units = {
    s: 1,
    m: 60,
    h: 3600,
    d: 86400,
  };

  const regex = /(\d+)\s*([smhd]?)/g;
  let match;
  let totalSeconds = 0;

  while ((match = regex.exec(timeString)) !== null) {
    const value = parseInt(match[1]);
    const unit = match[2] || 's'; // Default unit is seconds if not specified

    totalSeconds += value * units[unit];
  }

  return totalSeconds;
};

export const convertDateToTimestamp = (dateString) => {
  const [datePart, timePart] = dateString.split(' ');
  const [day, month, year] = datePart.split('/').map(Number);
  const [hours, minutes] = timePart.split(':').map(Number);
  const date = new Date(year, month - 1, day, hours, minutes);
  const timestamp = date.getTime() / 1000;
  return timestamp;
};

export const convertIsoDateToTimestamp = (isoDateString) => {
  if (typeof isoDateString !== 'string') {
    throw new Error('Date must be a string in ISO 8601 format.');
  }

  const date = new Date(isoDateString);

  if (isNaN(date.getTime())) {
    throw new Error('Invalid date.');
  }

  return date.getTime();
};

export const convertTimestampToIsoDate = (timestamp) => {
  if (typeof timestamp !== 'number') {
    throw new Error('Timestamp must be a number representing milliseconds.');
  }

  const date = new Date(timestamp);

  if (isNaN(date.getTime())) {
    throw new Error('Invalid timestamp.');
  }

  return date.toISOString();
};
