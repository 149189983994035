import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PanelData } from './components/common/PanelData';
import { NoActivity } from './components/view/no-activity/NoActivity';
import { Processing } from './components/view/processing/Processing';
import { useSocket } from '../../contexts/SocketContext';
import { useGetData } from '../../services/api/api-tools';
import { findLogPatternsWithScore } from '../../services/entity/pattern/pattern-api';
import { SpinnerIcon } from '../../components/loading/SpinnerIcon';
import { Error } from './components/view/error/Error';
import { extractAndFilterSubtypes, isHumanLog } from '../../services/log/log-service';

export const OperatorWorkstationPage = () => {
  const { workstationId } = useParams();

  const { data: workstation } = useGetData('workstation-id-ihm', `workstations/${workstationId}`);

  const { data: patterns } = useGetData(
    'patterns',
    `patterns?patternEventTypes.eventType.workstation.id=${workstationId}&isActive=true`,
  );

  const { data: eventTypes } = useGetData(
    'ihm_event_types',
    `event_types/workstation?workstation=${workstation?.id}&device=${workstation?.device?.id}`,
    {},
    !!workstation?.id && !!workstation?.device?.id,
  );

  const {
    data: patternFailures,
    isLoading,
    refetch,
  } = useGetData('patternFailures', 'pattern_failures', {
    'patternEventType.target.workstation.id': workstationId,
  });

  // Contains a pattern finished to display.
  const [completedPattern, setCompletedPattern] = useState(null);

  const [errorHistory, setErrorHistory] = useState([]);

  const { socket, subscribeToEvent, unsubscribeFromEvent, sendEvent } = useSocket();

  const [isInterfaceLoading, setIsInterfaceLoading] = useState(false);
  const [isErrorOnArea, setIsErrorOnArea] = useState({ state: false, content: '', title: '' });
  const [isReadyToReceiveLogs, setIsReadyToReceiveLogs] = useState(false);

  // Store all humans logs entering the workspace area; when a human exits the area, their human log ID is removed from this state.
  const [isHumanOnArea, setIsHumanOnArea] = useState(false);

  // Live logs received by the engine.
  const [logs, setLogs] = useState([]);

  // Predictions of patterns received by the engine with annotated confidence scores.
  const [patternsWithScore, setPatternsWithScore] = useState([]);

  const mockLogEnabled = process.env.REACT_APP_FEATURE_MOCK_IHM_LOGS === 'true';

  // Reset all state relative to operator process for a new cycle
  const handleReset = () => {
    setLogs([]);
    setPatternsWithScore([]);
  };

  const handleResetAreaError = async () => {
    setIsErrorOnArea({ state: false, content: '', title: '' });
  };

  const isLogAlreadyReceived = (newLog) => {
    return logs.some((log) => log.log_id === newLog.log_id);
  };

  // When receiving a new log, we check if it is assigned to an existing log; if so, we change its status to finish;
  // otherwise, we consider it as a new log and add it to the list
  const updateLog = (logs, newData) => {
    const existingLogIndex = logs.findIndex((log) => log.log_id === newData.log_id);
    if (existingLogIndex !== -1) {
      const updatedLogs = [...logs];
      updatedLogs[existingLogIndex] = { ...updatedLogs[existingLogIndex], is_finished: true };
      return updatedLogs;
    } else if (logs.length > 0) {
      const updatedLogs = [...logs];
      const lastLogIndex = updatedLogs.length - 1;
      updatedLogs[lastLogIndex] = { ...updatedLogs[lastLogIndex], is_finished: true };
      return [...updatedLogs, { ...newData, is_finished: false }];
    } else {
      return [...logs, { ...newData, is_finished: false }];
    }
  };

  // Show loader when a human enters the area
  useEffect(() => {
    if (isHumanOnArea) {
      setIsInterfaceLoading(true);
    } else {
      setIsInterfaceLoading(false);
    }
  }, [isHumanOnArea]);

  // Contact pattern service engine when receiving news logs to get patterns with score
  useEffect(() => {
    const handleChangesOnLogs = async () => {
      try {
        const lastLog = logs[logs.length - 1];
        // we do not contact the pattern prediction service if the change in logs is simply a state change of is_finished.
        if (!lastLog.is_finished) {
          setIsInterfaceLoading(true);
          console.log('[DEBUG:] Send to compute score', logs);
          const patternsWithScore = await findLogPatternsWithScore(logs, patterns);
          if (patternsWithScore.length) {
            console.log('[DEBUG:] From compute score', patternsWithScore);
            setPatternsWithScore(patternsWithScore);
          }
        }
      } catch (err) {
        console.error('Error while finding patterns with scores', err);
      } finally {
        setIsInterfaceLoading(false);
      }
    };
    if (logs.length) {
      handleChangesOnLogs();
    }
  }, [logs]);

  // Subscription to socket event to receive logs
  useEffect(() => {
    if (!isReadyToReceiveLogs) return;

    const handleEvent = async (log) => {
      if (completedPattern) {
        setCompletedPattern(null);
        handleReset();
        setErrorHistory([]);
        setLogs([]);
      }

      const subtypes = extractAndFilterSubtypes(eventTypes, workstation);
      if (isLogAlreadyReceived(log)) {
        setLogs((logs) => {
          const updatedLogs = updateLog(logs, log);
          return updatedLogs;
        });
      } else if (subtypes.includes(parseInt(log.subtype))) {
        if (!isHumanLog(log)) {
          if (log.subtype || isLogAlreadyReceived(log)) {
            setLogs((logs) => {
              const updatedLogs = updateLog(logs, log);
              return updatedLogs;
            });
          }
        } else {
          if (log.subtype === 3) {
            if (!logs.length) {
              setIsHumanOnArea(true);
            }
          } else if (log.subtype === 2) {
            setIsHumanOnArea(false);
          }
        }
      }
    };

    const handleErrorAreaEvent = async (error) => {
      if (parseInt(error.workstation_id) === parseInt(workstationId)) {
        setIsErrorOnArea({ state: true, content: error.content, title: error.title });
      }
    };

    const handleResetAreaErrorEvent = async (error) => {
      if (parseInt(error.workstation_id) === parseInt(workstationId)) {
        handleResetAreaError();
      }
    };

    subscribeToEvent('event', handleEvent);
    subscribeToEvent('error-ihm-area', handleErrorAreaEvent);
    subscribeToEvent('error-ihm-reset', handleResetAreaErrorEvent);

    return () => {
      unsubscribeFromEvent('event', handleEvent);
      unsubscribeFromEvent('error-ihm-area', handleErrorAreaEvent);
      unsubscribeFromEvent('error-ihm-reset', handleResetAreaErrorEvent);
    };
  }, [isReadyToReceiveLogs, completedPattern, logs, eventTypes, workstation]);

  useEffect(() => {
    if (!socket || !workstationId) return;
    setIsReadyToReceiveLogs(true);

    let interval;

    if (mockLogEnabled) {
      interval = setInterval(() => {
        sendEvent('ihm_user_connected', { workstation_id: workstationId });
      }, 2000);
    }

    return () => {
      clearInterval(interval);
      setIsReadyToReceiveLogs(false);
    };
  }, [socket, mockLogEnabled, workstationId]);

  return (
    <div className='h-full overflow-hidden bg-gray-100'>
      {isInterfaceLoading ? <OperatorLoader /> : ''}
      {isErrorOnArea.state ? <Error logs={logs} isErrorOnArea={isErrorOnArea} eventTypes={eventTypes} /> : ''}
      {logs.length ? (
        <Processing
          patternsWithScore={patternsWithScore}
          logs={logs}
          handleReset={handleReset}
          isErrorOnArea={isErrorOnArea}
          completedPattern={completedPattern}
          setCompletedPattern={setCompletedPattern}
          errorHistory={errorHistory}
          setErrorHistory={setErrorHistory}
          refetch={refetch}
          eventTypes={eventTypes}
        />
      ) : (
        <NoActivity />
      )}
      {workstation ? (
        <PanelData workstation={workstation} patternFailures={patternFailures} isLoading={isLoading} />
      ) : (
        ''
      )}
    </div>
  );
};

const OperatorLoader = () => {
  return (
    <div className='absolute top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-10 backdrop-blur-xs'>
      <SpinnerIcon />
    </div>
  );
};
