import moment from 'moment/moment';
import { useOrgData } from '../../contexts/OrgDataContext';
import { getFilteredAndGroupedLogs, getGroupedLogs, LOGS_COMMON_SUBTYPES } from '../../services/log/log-service';
import { LogFactory } from './LogFactory';
import { PauseCircleIcon, ViewfinderCircleIcon } from '@heroicons/react/24/outline';
import { memo, useEffect, useState } from 'react';
import { useSocket } from '../../contexts/SocketContext';
import { useTrialLiveContext } from '../../contexts/TrialLiveContext';

const MainLogPanelDemo = ({ logs }) => {
  const sortedLogs = [...logs].sort((a, b) => new Date(b.time) - new Date(a.time));
  const { groupedLogs, dates } = getFilteredAndGroupedLogs(sortedLogs, null);

  return (
    <div className='flex flex-col h-full text-perception-gray-500'>
      <div className='flex-1 overflow-y-auto'>
        <div className='min-w-full px-6 py-1 align-middle'>
          <div id='panel-logs-date' className='w-full'>
            {dates.length > 0 ? (
              dates.map((date) => (
                <div key={date} className='overflow-hidden'>
                  <span className='font-medium text-md text-perception-gray-500'>
                    {moment(date).format('DD/MM/YYYY')}
                  </span>
                  {groupedLogs[date].map((log, index) => (
                    <LogFactory key={`${log._value}_${index}`} log={log} />
                  ))}
                </div>
              ))
            ) : (
              <div className='text-sm text-perception-gray-500'>No logs found</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const MiniLogPanel = () => {
  const { workstationSelected } = useOrgData();
  const { socket, subscribeToEvent, unsubscribeFromEvent } = useSocket();
  const { targets } = useTrialLiveContext();

  const [logs, setLogs] = useState([]);
  const [isLogsProcessing, setIsLogsProcessing] = useState(false);

  useEffect(() => {
    if (!socket) return;

    const handleEvent = (data) => {
      data.time = moment.unix(data.time).toISOString();
      const isUserLog = targets.some((target) => target.id === parseInt(data.object_id));
      const isHumanLog =
        data?.subtype === LOGS_COMMON_SUBTYPES.HUMAN_IN_AREA || data?.subtype === LOGS_COMMON_SUBTYPES.NO_HUMAN_IN_AREA;
      if (isUserLog || isHumanLog) {
        setIsLogsProcessing(true);
        setLogs((allLogs) => {
          return [...allLogs, data];
        });
      }

      if (data?.subtype === LOGS_COMMON_SUBTYPES.NO_HUMAN_IN_AREA) {
        setIsLogsProcessing(false);
      }
    };

    const handleProcessing = (data) => {
      console.info('[DEBUG] Receive processing info:', data);
      setIsLogsProcessing(data.is_processing);
    };

    console.info('[DEBUG] Subscribe to events');
    subscribeToEvent('event', handleEvent);
    subscribeToEvent('processing', handleProcessing);

    return () => {
      unsubscribeFromEvent('event', handleEvent);
      unsubscribeFromEvent('processing', handleProcessing);
    };
  }, [socket, workstationSelected, targets]);

  if (!targets) return null;

  const { groupedLogs, dates } = getGroupedLogs(logs);

  return (
    <div className='flex flex-col h-full overflow-y-auto text-perception-gray-500'>
      <div className='flex items-center text-sm'>
        {isLogsProcessing ? (
          <>
            <svg
              className='w-5 h-5 mr-3 text-white animate-spin'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
            >
              <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4' />
              <path
                className='opacity-75'
                fill='currentColor'
                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
              />
            </svg>
            Processing
          </>
        ) : (
          <>
            <PauseCircleIcon className='w-5 h-5 mr-3' />
            Nothing currently detected
          </>
        )}
      </div>
      <div className='relative'>
        <div className='inset-0 flex items-center my-5' aria-hidden='true'>
          <div className='w-full border-t border-perception-gray-200' />
        </div>
      </div>
      <div id='panel-logs-date' className='h-full'>
        {dates.length ? (
          dates.map((date) => (
            <div key={date} className='overflow-hidden'>
              <span className='text-sm font-medium'>{moment(date).format('DD/MM/YYYY')}</span>
              {groupedLogs[date].map((log, index) => (
                <LogFactory key={index} view='mini' log={log} />
              ))}
            </div>
          ))
        ) : (
          <div className='flex flex-col items-center justify-center h-full text-perception-gray-200 text-md'>
            <ViewfinderCircleIcon className='w-8 h-8' />
            <span className='mt-2'>No logs generated yet</span>
          </div>
        )}
      </div>
    </div>
  );
};

export const LogPanelFactory = memo(function LogPanelFactory({ view, logs }) {
  if (view === 'mini') {
    return <MiniLogPanel logs={logs} />;
  } else {
    return <MainLogPanelDemo logs={logs} />;
  }
});
