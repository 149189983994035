import moment from 'moment/moment';
import { useOrgData } from '../../contexts/OrgDataContext';
import { usePatternSetup } from '../..//contexts/PatternSetupContext';
import { extractAndFilterSubtypes, filterArrayWithoutHumanLogs, getGroupedLogs } from '../../services/log/log-service';
import { getMoreLogs } from '../../services/log/log-api';
import { LogFactory } from './LogFactory';
import { useLog } from '../../contexts/LogContext';
import { ViewfinderCircleIcon } from '@heroicons/react/24/outline';
import { useEffect, useMemo, useRef } from 'react';
import { Loader } from '../loading/Loader';

export const LogPanelProduction = ({ setFocusedLogDate }) => {
  const { workstationSelected, eventTypes, isWorkstationDataLoading } = useOrgData();
  const {
    hoursToLookBack,
    aggregatedLogs,
    isLogsProcessing,
    setIsLogsProcessing,
    lastLogRetrievalDate,
    dateRange,
    isClientDateSetupExceeded,
    setIsClientDateSetupExceeded,
    setLastLogRetrievalDate,
    isInitialLogsRequested,
    setHoursToLookBack,
    getLogsByDateRange,
    setIsLogPanelFilled,
    updateRawLogs,
  } = useLog();
  const { isPatternPanelOpened, setLogsChecked, patternEventTypes, setPatternEventTypes } = usePatternSetup();

  const scrollRef = useRef(null);
  const containerLogsRef = useRef(null);

  const { groupedLogs, dates } = getGroupedLogs(aggregatedLogs);

  const allowFetchingMoreLogs = useMemo(
    () => !isLogsProcessing && !isWorkstationDataLoading && !isClientDateSetupExceeded && isInitialLogsRequested,
    [isLogsProcessing, isWorkstationDataLoading, isClientDateSetupExceeded, isInitialLogsRequested],
  );

  // relative to creation or editing pattern
  const handleSelectLog = (log, id, isChecked) => {
    const eventType = eventTypes.find((eventType) => eventType.subtype === log.subtype);
    const patternEventType = {
      id: id,
      order: patternEventTypes.length + 1,
      event_type: {
        id: eventType.id,
        name: eventType.name,
        subtype: eventType.subtype,
      },
      target: parseInt(log.object_id) > 0 ? { id: parseInt(log.object_id) } : null,
    };

    if (isChecked) {
      setPatternEventTypes((currentPatternEventTypes) => [...currentPatternEventTypes, patternEventType]);
    } else {
      setPatternEventTypes((currentPatternEventTypes) =>
        currentPatternEventTypes
          .filter((patternEventType) => patternEventType.id !== id)
          .map((patternEventType, index) => ({ ...patternEventType, order: index + 1 })),
      );
    }

    setLogsChecked((prevLogsChecked) => ({
      ...prevLogsChecked,
      [id]: isChecked,
    }));
  };

  // Handle the scroll event on the panel in two parts: managing the hovered log for positioning the timeline bar on the mini dashboard,
  // and managing the retrieval of older logs if the bottom of the log panel is reached
  useEffect(() => {
    const handleLogHover = () => {
      if (!scrollRef.current) return;

      const logsContainer = scrollRef.current;
      const visibleLogs = [];

      logsContainer.querySelectorAll('.log-element').forEach((logElement) => {
        const rect = logElement.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= logsContainer.clientHeight) {
          const logTime = logElement.getAttribute('data-log-time');
          visibleLogs.push(logTime);
        }
      });

      if (visibleLogs.length > 0) {
        setFocusedLogDate(visibleLogs[visibleLogs.length - 1]);
      }
    };

    const fetchOlderLogs = async () => {
      if (!scrollRef.current || !lastLogRetrievalDate || !allowFetchingMoreLogs) return;

      const totalHeight = scrollRef.current.scrollHeight;
      const windowHeight = scrollRef.current.clientHeight;
      const scrollPosition = scrollRef.current.scrollTop;

      if (scrollPosition + windowHeight >= totalHeight) {
        try {
          setIsLogsProcessing(true);
          const subtypes = extractAndFilterSubtypes(eventTypes, workstationSelected);
          const { logs, lastStart } = await getMoreLogs(lastLogRetrievalDate, subtypes);

          if (filterArrayWithoutHumanLogs(logs)?.length) {
            updateRawLogs(logs);
            setLastLogRetrievalDate(lastStart);
          } else {
            setIsClientDateSetupExceeded(true);
            setLastLogRetrievalDate(lastStart);
            updateRawLogs(logs);
          }
        } catch (err) {
          console.error(err);
        }
      } else {
        setIsLogsProcessing(false);
      }
    };

    const handleScroll = () => {
      handleLogHover();
      fetchOlderLogs();
    };

    if (scrollRef.current) {
      const scrollElement = scrollRef.current;
      scrollElement.addEventListener('scroll', handleScroll);

      return () => {
        scrollElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [scrollRef.current, workstationSelected, dateRange, eventTypes, lastLogRetrievalDate, allowFetchingMoreLogs]);

  // If the log panel is not filled, request older logs
  useEffect(() => {
    if (!scrollRef.current || !containerLogsRef.current) return;
    if (!allowFetchingMoreLogs) return;
    const scrollRefHeight = scrollRef.current.clientHeight;
    const containerLogsRefHeight = containerLogsRef.current.clientHeight;

    if (containerLogsRefHeight < scrollRefHeight) {
      setIsLogPanelFilled(false);
      let stopDate = lastLogRetrievalDate;
      const startDate = stopDate - hoursToLookBack * 3600 * 1000;
      const installationClientDate = new Date(parseInt(process.env.REACT_APP_INSTALLATION_CLIENT_DATE) * 1000);

      if (startDate > installationClientDate) {
        getLogsByDateRange(startDate, stopDate);
        setHoursToLookBack((prevDuration) => prevDuration * 2);
      } else {
        setIsClientDateSetupExceeded(true);
      }
    } else {
      setIsLogPanelFilled(true);
    }
  }, [lastLogRetrievalDate, hoursToLookBack, setHoursToLookBack, getLogsByDateRange, allowFetchingMoreLogs]);

  // If the machine or date filter is changed, scroll the user back to the top of the panel.
  useEffect(() => {
    const scrollElement = scrollRef.current;

    if (scrollElement) {
      scrollElement.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [workstationSelected, dateRange]);

  return (
    <div className='flex flex-col h-full text-perception-gray-500'>
      {isLogsProcessing && <Loader category='transparent' />}
      {isClientDateSetupExceeded && !aggregatedLogs.length ? (
        <div className='flex flex-col items-center justify-center h-full text-perception-gray-200 text-md'>
          <ViewfinderCircleIcon className='w-8 h-8' />
          <span className='mt-2'>No logs found</span>
        </div>
      ) : (
        ''
      )}
      <div ref={scrollRef} className='flex-1 overflow-y-auto'>
        <div ref={containerLogsRef} className='min-w-full align-middle'>
          <div id='panel-logs-date' className='w-full pl-4'>
            {dates.length > 0
              ? dates.map((date) => (
                  <div key={date}>
                    <span className='font-medium text-md text-perception-gray-500'>
                      {moment(date).format('DD/MM/YYYY')}
                    </span>
                    {groupedLogs[date].map((log, index) => (
                      <LogFactory
                        key={`${log._value}_${index}`}
                        view='production'
                        log={log}
                        isPatternPanelOpened={isPatternPanelOpened}
                        onSelectLog={handleSelectLog}
                        setFocusedLogDate={setFocusedLogDate}
                      />
                    ))}
                  </div>
                ))
              : ''}
          </div>
        </div>
      </div>
    </div>
  );
};
