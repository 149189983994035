import { Panel } from '../../../../components/panels/Panel';
import { LogPanelProduction } from '../../../../components/logs/LogPanelProduction';
import { InputSelect } from '../../../../components/forms/inputs/InputSelect';
import { optionAggregationDuration, optionFilterView, useLog } from '../../../../contexts/LogContext';
import { useOrgData } from '../../../../contexts/OrgDataContext';
import { ServiceStatusIndicator } from '../../../../components/others/ServiceStatusIndicator';
import { Calendar } from '../../../../components/others/Calendar';
import { useMemo } from 'react';

export const MainPanel = ({ setFocusedLogDate }) => {
  const {
    workstations,
    workstationSelected,
    setWorkstationSelected,
    isWorkstationDataLoading,
    setIsWorkstationDataLoading,
  } = useOrgData();

  const {
    logsAggregationDuration,
    setLogsAggregationDuration,
    filterView,
    setFilterView,
    isLogsProcessing,
    handleResetFlags,
    isLogPanelFilled,
  } = useLog();

  const isInputAggregationDisabled = () => filterView === 'DISPLAY_PATTERNS';

  const handleWorkstationChange = (selectedWorkstation) => {
    setIsWorkstationDataLoading(true);
    handleResetFlags();
    setWorkstationSelected(selectedWorkstation);
  };

  const inputDisabled = useMemo(
    () => !isLogPanelFilled || isWorkstationDataLoading || isLogsProcessing,
    [isLogPanelFilled, isWorkstationDataLoading, isLogsProcessing],
  );

  return (
    <div className='flex flex-col w-full h-full overflow-hidden'>
      <Panel category='main' title='ACTIVITY LOG'>
        <div className='flex flex-col w-full h-full'>
          <div className='flex items-center w-full'>
            <div className='w-1/4'>
              <InputSelect
                items={workstations}
                onChange={handleWorkstationChange}
                itemToString={(item) => item?.name}
                defaultValue={workstationSelected}
                disabled={inputDisabled}
              />
            </div>
            <div className='w-1/4 ml-3'>
              <InputSelect
                items={Object.values(optionFilterView)}
                onChange={(item) =>
                  setFilterView(Object.keys(optionFilterView).find((key) => optionFilterView[key].name === item.name))
                }
                itemToString={(item) => item?.name}
                defaultValue={optionFilterView[filterView]}
                disabled={isLogsProcessing}
              />
            </div>
            <div className='w-1/4 ml-3 mr-2'>
              <InputSelect
                items={Object.values(optionAggregationDuration)}
                onChange={(item) =>
                  setLogsAggregationDuration(
                    Object.keys(optionAggregationDuration).find(
                      (key) => optionAggregationDuration[key].value === item.value,
                    ),
                  )
                }
                itemToString={(item) => item?.name}
                defaultValue={optionAggregationDuration[logsAggregationDuration]}
                disabled={isInputAggregationDisabled() || isLogsProcessing}
              />
            </div>
            <Calendar useContext={useLog} contextType='engine' />
            <span className='ml-auto'>
              <ServiceStatusIndicator view='prod' />
            </span>
          </div>
          <div className='relative flex-1 w-full pt-6 overflow-hidden'>
            <LogPanelProduction setFocusedLogDate={setFocusedLogDate} />
          </div>
        </div>
      </Panel>
    </div>
  );
};
