import { useEffect, useRef } from 'react';
import { Panel } from '../../../../../components/panels/Panel';
import { Target } from '../../../../../components/logs/Target';
import { useTrialLiveContext } from '../../../../../contexts/TrialLiveContext';
import { useOrgData } from '../../../../../contexts/OrgDataContext';
import { generateRandomString } from '../../../../../helpers/common/generateRandomString';
import { CubeIcon } from '@heroicons/react/24/outline';
import { segmentSave } from '../../../../../services/ia/segmentation-api';
import { useAlert } from '../../../../../contexts/AlertContext';
import {
  InboxIcon,
  RectangleGroupIcon,
  PlusIcon,
  XMarkIcon,
  CloudArrowDownIcon,
  RocketLaunchIcon,
} from '@heroicons/react/24/solid';
import { HoverTextButton } from '../../../../../components/buttons/HoverTextButton';
import { captureSnapshot } from '../../../../../helpers/image/image-helper';
import { Button } from '../../../../../components/buttons/Button';
import { useTrialSetupContext } from '../../../../../contexts/TrialSetupContext';
import { addItemToCache } from '../../../../../services/api/api-tools';
import { useQueryClient } from 'react-query';

export const LeftPanel = () => {
  const { showAlert } = useAlert();
  const { workstationSelected } = useOrgData();
  const { videoRef } = useTrialSetupContext();
  const {
    setClickedPoints,
    setClickedNativePoints,
    isCreatingNewTarget,
    setIsCreatingNewTarget,
    clickedNativePoints,
    snapshot,
    setSnapshot,
    newTargetName,
    setNewTargetName,
    isViewLoadingData,
    setIsViewLoadingData,
    targetsQueryKey,
    device,
  } = useTrialLiveContext();

  const queryClient = useQueryClient();

  const handleSaveSegment = async () => {
    try {
      setIsViewLoadingData(true);
      if (clickedNativePoints.length) {
        const maskSave = await segmentSave(
          snapshot.object_id,
          clickedNativePoints.map((point) => [point.x, point.y]),
          newTargetName,
          workstationSelected,
          device.id,
          device.stream_id,
        );

        if (maskSave.status >= 200 && maskSave.status <= 300) {
          showAlert('success', 'Target has been successfully saved on database.');
          setNewTargetName('NEW TARGET');
          setIsCreatingNewTarget(false);
          setClickedPoints([]);
          setClickedNativePoints([]);
          addItemToCache(queryClient, targetsQueryKey, maskSave.data);
        } else {
          showAlert('error', 'Oops! Something went wrong while attempting to save the target.');
        }
      } else {
        showAlert('warning', 'You need to select at least one keypoint to save segment.');
      }
    } catch (err) {
      console.error('An error occurred while saving target:', err);
      showAlert('error', 'Oops! Something went wrong while attempting to save the target.');
    } finally {
      setIsViewLoadingData(false);
    }
  };

  useEffect(() => {
    if (isCreatingNewTarget) {
      if (videoRef.current?.srcObject) {
        setSnapshot({
          object_id: generateRandomString(5),
          img: captureSnapshot(videoRef),
        });
      }
    }
  }, [isCreatingNewTarget]);

  return (
    <div className='flex flex-col w-full h-full'>
      <div className='flex flex-col h-2/3'>
        <Panel title='CREATED TARGETS'>
          <div className='flex flex-col w-full h-full group/btn'>
            <div className='flex flex-col h-full overflow-auto'>
              <TargetsDisplay
                isCreatingNewTarget={isCreatingNewTarget}
                setIsCreatingNewTarget={setIsCreatingNewTarget}
                isViewLoadingData={isViewLoadingData}
              />
            </div>
            <div className='flex '>
              {isCreatingNewTarget && (
                <div className='flex text-xs'>
                  <Button
                    type='button'
                    size='small'
                    className='text-sm uppercase'
                    category='variant-btn'
                    onClick={() => setIsCreatingNewTarget(false)}
                    disabled={isViewLoadingData}
                  >
                    <XMarkIcon />
                    <span className='pl-2'>Cancel</span>
                  </Button>
                  <Button
                    type='button'
                    size='small'
                    className='ml-2 text-sm uppercase'
                    category='variant-btn'
                    onClick={handleSaveSegment}
                    disabled={isViewLoadingData}
                  >
                    <CloudArrowDownIcon />
                    <span className='pl-2'>Save</span>
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Panel>
      </div>
      <div className='relative flex flex-col flex-1'>
        <Panel title='PATTERN'>
          <div className='flex flex-col justify-between w-full h-full'>
            <div className='flex flex-col items-center justify-center h-full text-perception-gray-600 text-md'>
              <RectangleGroupIcon className='w-8 h-8' />
              <span className='mt-2'>No patterns on demo mode</span>
            </div>
            <a href='https://www.perception-manufacturing.com/contact' target='_blank' rel='noopener noreferrer'>
              <Button
                type='button'
                size='large'
                className='w-full uppercase'
                category='tertiary-btn'
                disabled={isViewLoadingData}
              >
                <RocketLaunchIcon />
                <span className='pl-2'>GET FULL ACCESS</span>
              </Button>
            </a>
          </div>
        </Panel>
      </div>
    </div>
  );
};

const TargetsDisplay = ({ isCreatingNewTarget, setIsCreatingNewTarget, isViewLoadingData }) => {
  const { snapshot, targets } = useTrialLiveContext();

  return (
    <div className='flex flex-col h-full'>
      {targets?.length > 0 && targets.map((target) => <Target key={target.id} target={target} />)}
      {snapshot && <NewTarget />}
      <div className='mt-4'>
        <HoverTextButton
          onClick={() => setIsCreatingNewTarget(!isCreatingNewTarget)}
          disabled={isViewLoadingData || isCreatingNewTarget}
        >
          <PlusIcon className='w-4 h-4' />
          <span className='pl-2'>Create a new target</span>
        </HoverTextButton>
      </div>
      {targets?.length === 0 && !isCreatingNewTarget && (
        <div className='flex flex-col items-center justify-center h-full text-perception-gray-600 text-md'>
          <InboxIcon className='w-8 h-8' />
          <span className='mt-2'>No targets created yet</span>
        </div>
      )}
    </div>
  );
};

const NewTarget = () => {
  const { clickedPoints, newTargetName, setNewTargetName, targets } = useTrialLiveContext();
  const inputRef = useRef(null);

  const handleNameChange = (event) => {
    setNewTargetName(event.target.value.toUpperCase());
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }

    const baseTargetName = 'NEW TARGET #';
    const targetName = targets?.length ? parseInt(targets?.length + 1) : 1;
    setNewTargetName(baseTargetName + targetName);
  }, []);

  return (
    <div className='flex items-center p-2'>
      <CubeIcon className='w-5 h-5 text-perception-blue' />
      <div className='flex flex-col'>
        <div className='flex'>
          <input
            ref={inputRef}
            type='text'
            value={newTargetName}
            onChange={handleNameChange}
            className='ml-4 text-xs font-semibold bg-transparent border outline-none text-perception-gray-500 border-perception-blue'
          />
        </div>
        <span className='pl-4 text-xs font-normal text-perception-gray-500'>{clickedPoints.length} keypoints</span>
      </div>
    </div>
  );
};
