import { useMemo, useState } from 'react';
import { Panel } from '../../../../components/panels/Panel';
import { InputSelect } from '../../../../components/forms/inputs/InputSelect';
import { useOrgData } from '../../../../contexts/OrgDataContext';
import { usePatternSetup } from '../../../../contexts/PatternSetupContext';
import { useLog } from '../../../../contexts/LogContext';
import { useAlert } from '../../../../contexts/AlertContext';
import { deleteData, removeItemFromCache } from '../../../../services/api/api-tools';
import { WarnModal } from '../pattern-panel/components/WarnModal';
import { PatternList } from './PatternList';
import { useQueryClient } from 'react-query';
import { WorkshopListState } from './components/WorkshopListState';
import { useSocket } from '../../../../contexts/SocketContext';

export const LeftPanel = () => {
  const { showAlert } = useAlert();
  const { workshops, workshopSelected, setWorkshopSelected, isWorkstationDataLoading, setIsWorkstationDataLoading } =
    useOrgData();
  const { handleResetFlags, isLogsProcessing, isLogPanelFilled } = useLog();
  const { setIsPatternPanelOpened, patternId, cleanEditingPattern, patternsQueryKey } = usePatternSetup();
  const { sendEvent } = useSocket();

  const [isWarnModalOpen, setIsWarnModalOpen] = useState(false);
  const [patternIdToDelete, setPatternIdToDelete] = useState(null);

  const displayWorkshopListState = process.env.REACT_APP_FEATURE_FLAG_WORKSHOP_LIST_STATE === 'true';

  const queryClient = useQueryClient();

  const onConfirmDelete = async (patternIdToDelete) => {
    try {
      await deleteData('patterns', patternIdToDelete);
      showAlert('success', 'The pattern has been successfully deleted from the database. ');
      removeItemFromCache(queryClient, patternsQueryKey, (pattern) => pattern.id === patternIdToDelete);
      sendEvent('ihm_update_patterns');
      if (patternId === patternIdToDelete) {
        cleanEditingPattern();
        setIsPatternPanelOpened(false);
      }
    } catch (err) {
      console.error('Something bad happened:', err);
      showAlert('warning', 'Oops! We encountered an issue while attempting to delete the pattern.');
    } finally {
      setIsWarnModalOpen(false);
      setPatternIdToDelete(null);
    }
  };

  const onWorkshopChange = (item) => {
    setIsWorkstationDataLoading(true);
    handleResetFlags();
    setWorkshopSelected(item);
  };

  const inputDisabled = useMemo(
    () => !isLogPanelFilled || isWorkstationDataLoading || isLogsProcessing,
    [isLogPanelFilled, isWorkstationDataLoading, isLogsProcessing],
  );

  return (
    <div className='flex flex-col w-full h-full'>
      <div className='flex flex-col h-1/3'>
        <Panel title='WORKSHOPS'>
          <InputSelect
            items={workshops}
            onChange={onWorkshopChange}
            itemToString={(item) => item?.name}
            defaultValue={workshopSelected}
            disabled={inputDisabled}
          />
          {displayWorkshopListState && <WorkshopListState workshops={workshops} />}
        </Panel>
      </div>
      <div className='flex flex-col flex-1'>
        <Panel title='PATTERN'>
          <PatternList setIsWarnModalOpen={setIsWarnModalOpen} setPatternIdToDelete={setPatternIdToDelete} />
        </Panel>
      </div>
      {isWarnModalOpen && (
        <WarnModal
          isOpen={isWarnModalOpen}
          setIsWarnModalOpen={setIsWarnModalOpen}
          onConfirmDelete={() => onConfirmDelete(patternIdToDelete)}
        />
      )}
    </div>
  );
};
