import { DEFAULT_HOURS_TO_LOOK_BACK } from '../../contexts/LogContext';
import { convertTimestampToIsoDate } from '../../helpers/date/date-helper';
import { getInfluxdbData } from '../api/influxdb-tools';
import { filterArrayWithoutHumanLogs } from './log-service';

// Fetch logs from a database for the specified stream IDs.
export const getLogs = async (subtypes, startTimestamp, stopTimestamp) => {
  const startDate = new Date(startTimestamp);
  const stopDate = new Date(stopTimestamp);

  const start = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString();
  const stop = new Date(stopDate.getTime() - stopDate.getTimezoneOffset() * 60000).toISOString();

  return await getInfluxdbData(
    'engine',
    'events',
    [],
    ['type', 'duration', ['subtype', '==', subtypes]],
    start,
    stop,
    '1ms',
    null,
    null,
    [],
    null,
    null,
    false,
    'desc',
  );
};

// Fetches additional logs from a database based on the last log time and stream IDs.
export const getMoreLogs = async (lastLogTime, subtypes, targetNonHumanLogsCount = 50) => {
  let hoursToLookBack = DEFAULT_HOURS_TO_LOOK_BACK;

  const installationClientDate = parseInt(process.env.REACT_APP_INSTALLATION_CLIENT_DATE) * 1000;

  let allLogs = [];
  let lastStartDate = null;

  while (filterArrayWithoutHumanLogs(allLogs).length < targetNonHumanLogsCount) {
    const startTimestamp = lastLogTime - hoursToLookBack * 60 * 60 * 1000;
    const stopTimestamp = lastLogTime - (hoursToLookBack / 2) * 60 * 60 * 1000;

    if (startTimestamp < installationClientDate) {
      break;
    }

    const start = convertTimestampToIsoDate(startTimestamp);
    const stop = convertTimestampToIsoDate(stopTimestamp);

    const response = await getInfluxdbData(
      'engine',
      'events',
      [],
      ['type', 'duration', ['subtype', '==', subtypes]],
      start,
      stop,
      '1ms',
      null,
      null,
      [],
      null,
      null,
      false,
      'desc',
    );

    allLogs.push(...response);
    lastStartDate = startTimestamp;
    hoursToLookBack *= 2;
  }

  return {
    logs: allLogs,
    lastStart: lastStartDate,
  };
};
