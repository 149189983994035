import { useState } from 'react';
import { deleteData } from '../../../../services/api/api-tools';
import { WarnModal } from '../pattern-panel/components/WarnModal';
import { useAlert } from '../../../../contexts/AlertContext';
import { AlertPanel } from './components/AlertPanel';
import { DashboardPanel } from './components/DashboardPanel';

export const RightPanel = ({ focusedLogDate }) => {
  const { showAlert } = useAlert();

  const [alerts, setAlerts] = useState([]);
  const [alertIdToDelete, setAlertIdToDelete] = useState(null);

  const [isWarnModalOpen, setIsWarnModalOpen] = useState(false);

  const onConfirmDeleteAlert = async (alertId) => {
    try {
      await deleteData('alerts', alertId);
      showAlert('success', 'The alert has been successfully deleted from the database.');
      setAlerts(alerts.filter((alert) => alert.id !== alertId));
    } catch (err) {
      console.error('Something bad happened:', err);
      showAlert('warning', 'Oops! Something went wrong while attempting to delete the target.');
    } finally {
      setIsWarnModalOpen(false);
      setAlertIdToDelete(null);
    }
  };

  return (
    <div className='flex flex-col w-full h-full'>
      <div className='h-4/6'>
        <AlertPanel
          alerts={alerts}
          setAlerts={setAlerts}
          setIsWarnModalOpen={setIsWarnModalOpen}
          setAlertIdToDelete={setAlertIdToDelete}
        />
      </div>
      <div className='flex flex-col flex-1'>
        <DashboardPanel focusedLogDate={focusedLogDate} />
      </div>
      {isWarnModalOpen && (
        <WarnModal
          isOpen={isWarnModalOpen}
          setIsWarnModalOpen={setIsWarnModalOpen}
          onConfirmDelete={() => onConfirmDeleteAlert(alertIdToDelete)}
        />
      )}
    </div>
  );
};
