import { createContext, useContext, useMemo, useState, useEffect } from 'react';
import { useUser } from './UserContext';
import { useWorkshopsByOrganization } from '../services/entity/workshop/workshop-api';
import { fetchData } from '../services/api/api-tools';

export const OrgDataContext = createContext();

export const useOrgData = () => useContext(OrgDataContext);

export const OrgDataProvider = ({ children }) => {
  const { user } = useUser();

  const [workshopSelected, setWorkshopSelected] = useState('');
  const [workstations, setWorkstations] = useState([]);
  const [workstationSelected, setWorkstationSelected] = useState('');
  const [eventTypes, setEventTypes] = useState([]);
  const [calibrations, setCalibrations] = useState([]);

  const [isWorkstationDataLoading, setIsWorkstationDataLoading] = useState(true);

  const [streamIds, setStreamIds] = useState([]);

  const organizationId = useMemo(() => user?.organization?.id, [user]);

  const workshops = useWorkshopsByOrganization(organizationId);

  // Fetches and transforms eventTypes based on the selected workstation
  useEffect(() => {
    if (workstationSelected?.id > 0) {
      const fetchAndTransformEventTypes = async () => {
        try {
          setIsWorkstationDataLoading(true);
          const eventTypes = await fetchData(
            `event_types/workstation?workstation=${workstationSelected.id}&device=${workstationSelected.device.id}`,
          );
          console.info('[DEBUG] Retrieve event types', eventTypes);
          if (eventTypes) {
            setEventTypes(eventTypes);
          }
        } catch (err) {
          console.error('Something bad happened:', err);
        } finally {
          setIsWorkstationDataLoading(false);
        }
      };

      fetchAndTransformEventTypes();
    }
  }, [workstationSelected]);

  useEffect(() => {
    if (workshops?.length) {
      const selectedWorkshop = workshops[0];
      setWorkshopSelected(selectedWorkshop);
    }
  }, [workshops]);

  useEffect(() => {
    if (workshopSelected) {
      setWorkstations(workshopSelected.workstations || []);
      setStreamIds(workshopSelected.devices?.map((device) => device.stream_id) || []);
      if (workshopSelected.device_calibration_histories) setCalibrations(workshopSelected.device_calibration_histories);
    }
  }, [workshopSelected]);

  useEffect(() => {
    if (workstations?.length) {
      const sortedWorkstations = [...workstations].sort((a, b) => a.id - b.id);
      setWorkstationSelected(sortedWorkstations[0]);
    }
  }, [workstations]);

  const contextValue = useMemo(
    () => ({
      workshops,
      workshopSelected,
      setWorkshopSelected,
      workstations,
      workstationSelected,
      setWorkstationSelected,
      streamIds,
      setStreamIds,
      eventTypes,
      calibrations,
      setCalibrations,
      isWorkstationDataLoading,
      setIsWorkstationDataLoading,
    }),
    [
      workshops,
      workshopSelected,
      setWorkshopSelected,
      workstations,
      workstationSelected,
      setWorkstationSelected,
      streamIds,
      setStreamIds,
      eventTypes,
      calibrations,
      setCalibrations,
      isWorkstationDataLoading,
      setIsWorkstationDataLoading,
    ],
  );

  return <OrgDataContext.Provider value={contextValue}>{children}</OrgDataContext.Provider>;
};
